import {FC, useEffect} from 'react';
import {Box, Container} from '@mui/material';
import {Magazine} from 'components/page/leadgeneration/components/success/magazine/Magazine';
import {Advantages} from 'components/page/leadgeneration/components/success/advantages/Advantages';
import {Head as SuccessPageHead} from 'components/page/leadgeneration/components/success/head/Head';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';

export const Success: FC = () => {
    const { gtm: { sendEvent } } = useAnalytics();

    useEffect(() => {
        sendEvent({
            'event': 'track-event',
            'category': 'lead-gen',
            'action': 'odoslany-formular',
        });
        window.scrollTo(0, 0);
    }, [sendEvent]);

    return <Container maxWidth="lg" sx={{paddingTop: 3, paddingBottom: 10}}>

        <Box mb={3}>
            <SuccessPageHead/>
        </Box>

        <Box mb={5}>
            <Magazine/>
        </Box>

        <Box>
            <Advantages/>
        </Box>

    </Container>;
};

'use client';

import {FC} from 'react';
import {Container,} from '@mui/material';
import { useSearchParams, useRouter, usePathname } from 'next/navigation';
import {Success} from 'components/page/leadgeneration/components/success/Success';
import {Initial} from 'components/page/leadgeneration/components/initial/Initial';
import {useLeadGenActions} from 'components/page/leadgeneration/hooks/useLeadGenActions';

const Main: FC = () => {
    const router = useRouter();
    const pathname = usePathname();
    const params = useSearchParams();
    const { onFormInputTouch } = useLeadGenActions();
    const isFinished = Boolean(params?.get('finished')) || false;

    const handleOnFinish = async () => {
        const urlParams = new URLSearchParams(params?.toString());
        urlParams.append('finished', 'true');

        router.push(`${pathname}?${urlParams.toString()}`);
    };

    return <Container maxWidth={false} disableGutters={true}>
        {isFinished
            ? <Success/>
            : <Initial
                onFinish={handleOnFinish}
                isFinished={isFinished}
                onFormInputTouch={onFormInputTouch}
            />
        }
    </Container>;
};

export default Main;
